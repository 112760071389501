import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import HelpModal from './HelpModal';
import {Link} from "react-router-dom";

const ReceiptGenerator = () => {
    const [formData, setFormData] = useState({
        address: '',
        name: '',
        registrationNumber: '',
        phoneNumber: '',
        email: '',
        total: '',
        shippingFee: '',
        receiptDate: '',
        receiptNumber: '',
        recipientName: '',
        recipientTitle: '',
        isReducedTaxRate: false
    });

    const [tax, setTax] = useState({ rate8: 0, rate10: 0 });

    useEffect(() => {
        // LocalStorageからデータを読み込む
        const savedData = localStorage.getItem('receiptFormData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setFormData(prevData => ({
                ...prevData,
                ...parsedData,
                total: parsedData.total || '',
                shippingFee: parsedData.shippingFee || '',
                receiptDate: parsedData.receiptDate || ''
            }));
        }

        // 初期の領収番号を生成
        generateReceiptNumber();
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const saveToLocalStorage = () => {
        localStorage.setItem('receiptFormData', JSON.stringify(formData));
    };

    const calculateTax = () => {
        const total = Number(formData.total) || 0;
        const shippingFee = Number(formData.shippingFee) || 0;

        if (formData.isReducedTaxRate) {
            const taxableAmount = total - shippingFee;
            const tax8 = Math.floor((taxableAmount / 1.08) * 0.08);
            const tax10 = Math.floor((shippingFee / 1.1) * 0.1);
            setTax({ rate8: tax8, rate10: tax10 });
        } else {
            const tax10 = Math.floor((total / 1.1) * 0.1);
            setTax({ rate8: 0, rate10: tax10 });
        }
    };

    const getTodayDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const generateReceiptNumber = () => {
        const date = formData.receiptDate ? new Date(formData.receiptDate) : new Date();
        const yymmdd = date.toISOString().slice(2, 10).replace(/-/g, '');
        const randomHex = Math.floor(Math.random() * 0xFFFFFF).toString(16).padStart(6, '0').toUpperCase();
        const newReceiptNumber = `${yymmdd}-${randomHex}`;
        setFormData(prevData => ({ ...prevData, receiptNumber: newReceiptNumber }));
    };

    useEffect(() => {
        calculateTax();
    }, [formData.total, formData.shippingFee, formData.isReducedTaxRate]);

    const generatePDF = () => {
        const input = document.getElementById('receipt-content-pdf');
        if (!input) {
            console.error('Receipt content element not found');
            return;
        }

        const a4WidthMm = 210;
        const a4HeightMm = 297;
        const mmToPx = 3.7795275591;
        const a4WidthPx = a4WidthMm * mmToPx;
        const a4HeightPx = a4HeightMm * mmToPx;

        html2canvas(input, {
            scale: 2,
            width: a4WidthPx,
            height: a4HeightPx,
            useCORS: true,
            logging: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            pdf.addImage(imgData, 'JPEG', 0, 0, a4WidthMm, a4HeightMm);

            const fileName = `TEKIKAK-RECEIPT-FROM${formData.name || 'TEKIKAK-RECEIPT'}-${formData.total}YEN-AT${formData.receiptDate}-NO${formData.receiptNumber}.pdf`;
            pdf.save(fileName);
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    const ReceiptContent = ({ isPDF = false }) => (
        <div className={`relative flex flex-col justify-center p-4 border rounded ${isPDF ? 'w-[210mm] h-[297mm]' : ''}`}>
            <div className={`${isPDF ? 'absolute top-[30mm] right-[30mm] bottom-[30mm] left-[30mm]' : ''} flex flex-col`}>
                <div className="text-right mb-8">
                    <p>領収日 {formData.receiptDate}</p>
                    <p>領収番号 {formData.receiptNumber}</p>
                </div>

                <h1 className="text-4xl font-bold text-center mb-12">領収証</h1>

                <p className="text-xl mb-8">{formData.recipientName} 様</p>

                <div className="text-4xl font-bold mb-12 relative">
                    <p className="pb-5">{Number(formData.total).toLocaleString()}- 円</p>
                    <div className="absolute bottom-0 left-0 right-0 border-b-2 border-black"></div>
                </div>

                <div className="flex justify-end mb-8">
                    <div>
                        {formData.isReducedTaxRate && (
                            <p>消費税(8%)相当額 {tax.rate8.toLocaleString()}円</p>
                        )}
                        <p>消費税(10%)相当額 {tax.rate10.toLocaleString()}円</p>
                    </div>
                </div>

                <p className="mb-12">但し {formData.recipientTitle} として領収いたしました</p>

                <div className="mt-auto">
                    <p>{formData.address}</p>
                    <p>{formData.name}</p>
                    <p>登録番号：{formData.registrationNumber}</p>
                </div>
            </div>
        </div>
    );

    return (
        <div className="max-w-2xl mx-auto mt-5">
            <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
                <h2 className="text-xl text-center font-bold border-b pb-3">あなたの情報</h2>
                <HelpModal
                    id="name-help"
                    title="個人情報の入力は必須ですか？仮名ではダメですか？"
                    content="税務調査などで確認すべき事項があった場合に連絡がつかなければなりません。あらぬ疑いをかけられないためにも、連絡がつく情報を入力しましょう。また、古物商許可を持った人が個人から仕入れた商品の消費税の仕入控除を受ける場合、仕入れた相手の名前、住所、連絡先を記録しておかなければなりません。"
                />
                <div className="flex flex-col gap-4">
                    <label htmlFor="address">住所/所在地</label>
                    <input name="address" value={formData.address} onChange={handleInputChange} placeholder="長野県茅野市豊平南大塩" className="w-full p-2 border rounded" />
                    <label htmlFor="name">氏名/社名など</label>
                    <input name="name" value={formData.name} onChange={handleInputChange} placeholder="株式会社hatte" className="w-full p-2 border rounded" />
                    <label htmlFor="registrationNumber">登録番号</label>
                    <input name="registrationNumber" value={formData.registrationNumber} onChange={handleInputChange} placeholder="インボイス登録番号をお持ちの場合入れてください" className="w-full p-2 border rounded" />
                    <HelpModal
                        id="registration-help"
                        title="登録番号とはなんですか？"
                        content="あなたがもし消費税の適格請求書発行時業者である場合、税務署長から通知された番号です。Tのあとに個人番号や法人番号が続いたものになります。この番号が入力されていると、領収書を受けた側は消費税の仕入控除を受けることができます。"
                    />
                    <label htmlFor="phoneNumber">電話番号</label>
                    <input name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} placeholder="09000000000" className="w-full p-2 border rounded" />
                    <label htmlFor="email">メールアドレス</label>
                    <input name="email" value={formData.email} onChange={handleInputChange} placeholder="tekikak@hatte.co.jp" className="w-full p-2 border rounded" />
                </div>
                <button type="button" onClick={saveToLocalStorage} className="w-full p-2 bg-blue-500 text-white rounded">ブラウザに情報を記憶させる</button>
                <p className="mb-5">記憶させると次回以降の入力が不要になります。端末に記録されるため、当社は利用者様の情報にアクセスすることはできません。</p>

                <h2 className="text-xl text-center font-bold border-b pb-3">領収書情報</h2>
                <div className="flex items-center space-x-2">
                    <label>合計
                        <input name="total" type="number" value={formData.total} onChange={handleInputChange} placeholder="合計" className="flex-grow p-2 border rounded" />
                    </label>
                    <p>円</p>
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="isReducedTaxRate"
                            checked={formData.isReducedTaxRate}
                            onChange={handleInputChange}
                            className="mr-2"
                        />
                        軽減税率対象
                    </label>
                </div>
                <div className="flex items-center space-x-2">
                    <label>┗　送料などの額(10%対象)
                        <input name="shippingFee" type="number" value={formData.shippingFee} onChange={handleInputChange} placeholder="送料相当額" className="flex-grow p-2 border rounded" />
                    </label>
                    <p>円</p>
                </div>
                <p>※<b>合計額は送料も含めた金額</b>です。軽減税率対象品の場合は送料を送料欄に記入し、軽減税率のチェックを入れてください</p>
                <p>※軽減税率対象品がない場合は送料欄は入力不要です</p>
                <HelpModal
                    id="tax-help"
                    title="商品の消費税に8%のものと10%のものが混在しています"
                    content="総額を入力して軽減税率を有効にしてください。かつ、10%対象の商品の税込み額と送料の合計額を送料などの額欄に入力してください。"
                />
                <div className="flex items-center space-x-2">
                <label>領収日
                    <input name="receiptDate" type="date" value={formData.receiptDate} onChange={handleInputChange} className="flex-grow p-2 border rounded" />
                </label>
                </div>
                    <div className="flex items-center space-x-2">
                <label>領収番号
                    <input name="receiptNumber" value={formData.receiptNumber} onChange={handleInputChange} placeholder="領収番号" className="flex-grow p-2 border rounded" readOnly />
                </label>
                    </div>
                <h2 className="text-xl font-bold">相手の情報</h2>
                <div className="flex items-center space-x-2">
                    <label>宛名
                        <input name="recipientName" value={formData.recipientName} onChange={handleInputChange} placeholder="敬称は 様 になります" className="flex-grow p-2 border rounded" />
                    </label>
                    <p>様</p>
                </div>
                <div className="flex items-center space-x-2">
                    <label>但し
                        <input name="recipientTitle" value={formData.recipientTitle} onChange={handleInputChange} placeholder="商品名を具体的に入力してください" className="flex-grow p-2 border rounded" />
                    </label>
                    <p>として</p>

                </div>
                <HelpModal
                    id="description-help"
                    title="但し書きはどう書くべきですか？"
                    content="一般的に「品代」などざっくりとしたものは避けるべきです。どのような商品かわかるような書き方を心がけましょう。"
                />
            </form>
            <div className="mt-8 overflow-hidden">
                <div className="origin-top-left">
                    <div id="receipt-content-preview">
                        <ReceiptContent isPDF={false} />
                    </div>
                </div>
            </div>
            <p>※PDFはA4サイズにレイアウト調整されます</p>
            <HelpModal
                id="pdf-help"
                title="もっとかっこいい領収書にできないんですか？"
                content="領収書の要件として取引内容がわかりやすく書かれていなければなりません。そこで、シンプルですがこのようなレイアウトになっています。"
            />

            {/* PDF生成用の非表示要素 */}
            <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
                <div id="receipt-content-pdf">
                    <ReceiptContent isPDF={true} />
                </div>
            </div>

            <div><Link to="/legal" className="mt-4 inline-block text-blue-500 hover:text-blue-700 underline">
                利用規約を確認する
            </Link></div>
            <button onClick={generatePDF} className="mt-4 w-full p-2 bg-green-500 text-white rounded">
                利用規約に同意して領収証をダウンロード
            </button>
            <HelpModal
                id="print-help"
                title="直接印刷できないのはなぜですか？"
                content="正本はPDFです。正本を紙にした場合、印紙税の対象となります。正本をPDFに固定するため、必ずダウンロードするような設計になっています。PDF領収書を保管等のために印刷する場合は、印紙税の対象とはなりません。"
            />
        </div>
    );
};

export default ReceiptGenerator;