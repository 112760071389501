import React,{ useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation ,Link } from 'react-router-dom';
import ReceiptGenerator from './ReceiptGenerator';
import LegalPage from './LegalPage';
import ReactGA from "react-ga4";
ReactGA.initialize("G-LF7SL47HT6");

const PageViewTracker = () => {
    const location = useLocation();

    useEffect(() => {
        // ロケーションが変更されるたびにページビューを送信
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null; // このコンポーネントは何もレンダリングしない
};
const Home = () => (
    <div className="container max-w-2xl mx-auto p-12">
        <h1 className="text-2xl font-bold mb-4">領収書発行サービス TEKIKAK Free β</h1>
        <p className="mb-4">
            TEKIKAK Free はフリマなどで領収書の発行を依頼されたときに、サクッとPDF領収書を作ってダウンロードできるサービスです。<br/>
            運営は株式会社hatte が行っており、原則として入力した情報は利用者様のパソコンなどの端末内に保存し、外部への送信はしないように設計されています。
        </p>
        <p className="mb-4">
            サービスの改善を目的として、利用者様の操作状況（領収書発行ボタンの操作や、ヘルプの表示など）をGoogle Analyticsを使用して解析しております。これらは個人を特定しない形で行われておりますので、ご安心ください。
        </p>
        <p>
            他人の領収書をその他人に代わって無断で発行することは私文書の偽造にあたります。また、偽造した領収書を用いて経費の精算をしたりすることは、偽変造私文書行使罪にあたります。当社はこのような犯罪行為に当サービスが使用されることを意図しておりません。
        </p>
        <ReceiptGenerator />
    </div>
);

const App = () => {
    return (
        <div className="container max-w-2xl mx-auto p-12">
            <h1 className="text-2xl font-bold mb-4">領収書発行サービス TEKIKAK Free β</h1>
            <p>
                TEKIKAK Free はフリマなどで領収書の発行を依頼されたときに、サクッとPDF領収書を作ってダウンロードできるサービスです。<br/>
                運営は株式会社hatte が行っており、原則として入力した情報は利用者様のパソコンなどの端末内に保存し、外部への送信はしないように設計されています。
            </p>
            <Routes>
                <Route path="/" element={<ReceiptGenerator />} />
                <Route path="/legal" element={<LegalPage />} />
            </Routes>
        </div>
    );
}

export default App;